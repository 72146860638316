import { match } from 'path-to-regexp';
import ROUTES, { type TAvailableTemplates } from './routes';
import type { TActiveRoute } from './types';
import type { TMarket, TMarketSlug } from '../market/types';
import { getMarketDetailsBySlug } from '../market/utils';
import { IDDQD_ROUTE_ID } from './sveltekit_constant_routes';

const getActiveRouteItem = (
    market: string,
    url: string,
    origin: string,
    params?: Record<string, string>,
    path?: string
): TActiveRoute => ({
    origin,
    url,
    params,
    path,
    market
});

const getPageRequestUrl = (requestedPathname: string) => {
    const noLeadingSlash = requestedPathname.startsWith('/')
        ? requestedPathname.substring(1)
        : requestedPathname;
    const pathname = noLeadingSlash.endsWith('/') ? noLeadingSlash.slice(0, -1) : noLeadingSlash;

    if (pathname.includes('/page/')) {
        return pathname.replace(/\/page\/[0-9]+\/?/gu, '');
    } else {
        return pathname;
    }
};

const getDevelopmentRouteInfo = (url: URL, requestedMarket: TMarketSlug) => {
    const market = getMarketDetailsBySlug(requestedMarket) as TMarket;
    const route = getActiveRouteItem(market.slug, url.pathname, url.origin);

    return {
        market,
        route
    };
};
const isDevelopmentRoute = (routeId: string) => routeId.startsWith(IDDQD_ROUTE_ID);

export const getActiveRouteInfo = ({
    url,
    params,
    routeId,
    requestMarket
}: {
    url: URL;
    params: Partial<Record<string, string>>;
    routeId: string;
    requestMarket: TMarketSlug;
}): { market: TMarket; route: TActiveRoute } | undefined => {
    if (isDevelopmentRoute(routeId)) return getDevelopmentRouteInfo(url, requestMarket);

    if (url.pathname.startsWith('/es/')) {
        return undefined;
    }
    const routeWithMarket = requestMarket === 'es' ? `/es${url.pathname}` : url.pathname;
    const pathname = getPageRequestUrl(routeWithMarket);
    // Routes with .../[market]/... OVERRULE routes with en/...
    // when it comes to market
    const marketSlug = (requestMarket || params.market || pathname.split('/')[0]) as TMarketSlug;
    const market = getMarketDetailsBySlug(marketSlug);
    if (!market) return undefined;

    const templateName = extractTemplateNameFromRouteId(routeId);
    if (!templateName || !ROUTES[templateName]) return undefined;

    // default
    let route = getActiveRouteItem(market.slug, pathname, url.origin);
    const routes = ROUTES[templateName];

    const [routeSlugified] =
        routes.slugified.find(([, routeRegex]) => routeRegex.test(pathname)) || [];

    if (routeSlugified) {
        const details = match<Record<string, string>>(routeSlugified, {
            decode: decodeURIComponent
        })(pathname);
        if (details) {
            route = getActiveRouteItem(
                market.slug,
                pathname,
                url.origin,
                details.params,
                details.path
            );
        }
    }
    return {
        market,
        route
    };
};

export const getActiveRoute = (url: string | undefined, template: string = '', origin: string) => {
    const routes = ROUTES[template];

    if (!url || !routes) {
        return null;
    }

    let requestedUrl = url;
    const market = url.split('/')[0];

    if (requestedUrl.includes('/page/')) {
        requestedUrl = requestedUrl.replace(/\/page\/[0-9]+\/?/gu, '');
    }

    if (routes.default.has(requestedUrl)) {
        return getActiveRouteItem(market, requestedUrl, origin);
    } else {
        const activeRoute = routes.slugified.find((route) => route[1].test(requestedUrl))?.[0];

        if (activeRoute) {
            const details = match<Record<string, string>>(activeRoute, {
                decode: decodeURIComponent
            })(requestedUrl);

            if (details) {
                return getActiveRouteItem(
                    market,
                    requestedUrl,
                    origin,
                    details.params,
                    details.path
                );
            }
        }
    }
    return null;
};

export const extractTemplateNameFromRouteId = (routeId: string) => {
    const extractTemplateRegExp = new RegExp(/\(([^)]+)\)/g);
    return routeId.match(extractTemplateRegExp)?.join('/');
};

export const isMatchingRoute = (pathname: string, template: TAvailableTemplates): boolean => {
    const pageRequestUrl = getPageRequestUrl(pathname);

    // check if matched route template is known to us

    if (ROUTES[template]) {
        //check if is default route
        if (ROUTES[template].default.has(pageRequestUrl)) {
            return true;
        } else if (ROUTES[template].slugified.some(([, r]) => r.test(pageRequestUrl))) {
            return true;
        } else if (!pageRequestUrl.startsWith('es/')) {
            return isMatchingRoute('es/' + pathname, template);
        }
    }
    //Not matching anything
    return false;
};
